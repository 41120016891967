.search-location {
  position: relative;
}
.search-location .MuiInputLabel-outlined {
  z-index: unset;
}
.search-location:not(.input-default-style) input[type="text"] {
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  color: #5C6670;
  box-sizing: border-box;
  outline: none;
  border-radius: 6px;
  height: auto;
}
html:not([dir="rtl"]) .search-location:not(.input-default-style) input[type="text"] {
  padding: 10px 40px 10px 16px;
}
html[dir="rtl"] .search-location:not(.input-default-style) input[type="text"] {
  padding: 10px 16px 10px 40px;
}
.search-location button {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  border: none;
  background: url(/css/img/close.svg) center center / cover no-repeat;
  outline: none;
  cursor: pointer;
}
html:not([dir="rtl"]) .search-location button {
  right: 14px;
}
html[dir="rtl"] .search-location button {
  left: 14px;
}
.search-location-results {
  position: absolute;
}
.search-location-results.portal {
  z-index: 9999;
}
.search-location-results.search-results-variant-1 {
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  min-width: 50%;
}
.search-location-results.search-results-variant-1.open-to-bottom {
  top: calc(100% + 5px);
}
.search-location-results.search-results-variant-1.open-to-top {
  bottom: calc(100% + 5px);
}
.search-location-results.search-results-variant-1.visible {
  opacity: 1;
}
.search-location-results.search-results-variant-1 .search-location-results-wrapper {
  box-shadow: 0px 4px 15px rgba(107, 156, 212, 0.2);
}
.search-location-results.search-results-variant-2 {
  top: 0;
  width: 100%;
}
.search-location-results-wrapper {
  background: #fff;
  overflow: hidden;
}
.search-location-results.search-results-variant-1 .search-location-results-wrapper {
  border: 1px solid #dce1ec;
  border-radius: 8px;
}
.search-location-results.search-results-variant-2 .search-location-results-wrapper {
  transition: height 0.3s ease 0s;
  padding-top: 44px;
}
.search-location-results.search-results-variant-2:not(.visible) .search-location-results-wrapper {
  height: 0;
}
.search-location-results.search-results-variant-2:not(.input-default-style) .search-location-results-wrapper  {
  border-radius: 6px;
}
.search-location-results.search-results-variant-2 .search-location-results-value {
  border-top: 1px solid #E1E6F5;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.search-location-results.search-results-variant-2.visible .search-location-results-value {
  opacity: 1;
}
.search-location-results .search-location-searching {
  padding: 7px 14px;
  font-size: 17px;
}
.search-location-results .search-location-not-found {
  padding: 7px 14px;
  font-size: 17px;
}
.search-location-results li {
  padding: 13px 24px;
  cursor: pointer;
  list-style: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  position: relative;
}
.search-location-results li:not(:last-child) {
  padding-bottom: 14px;
}
.search-location-results li:not(:last-child):after {
  content: '';
  display: block;
  height: 1px;
  background: #E1E6F5;
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
}
html:not([dir="rtl"]) .search-location-results li.with-delete-button {
  padding-right: 46px;
}
html[dir="rtl"] .search-location-results li.with-delete-button {
  padding-left: 46px;
}
.search-location-results li a {
  position: absolute;
  display: block;
  top: 50%;
  transform: translate(0, -50%);
}
html:not([dir="rtl"]) .search-location-results li a {
  right: 27px;
}
html[dir="rtl"] .search-location-results li a {
  left: 27px;
}
.search-location-results li a svg {
  display: block;
}
.search-location-results li.target {
  background: #f7f7f7;
}
.search-location-results img {
  width: 15px;
  vertical-align: middle;
}
html:not([dir="rtl"]) .search-location-results img {
  margin-right: 10px;
}
html[dir="rtl"] .search-location-results img {
  margin-left: 10px;
}
.search-location-results > ul > li > span {
  vertical-align: middle;
  display: inline-block
}
.search-location-results > ul > li > span:first-of-type {
  position: relative;
}
.search-location-results > ul > li > span:first-of-type:before {
  content: '';
  display: block;
  width: 19px;
  height: 13px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0px 0px 0px 1px #cbcbcb;
}
html:not([dir="rtl"]) .search-location-results > ul > li > span:first-of-type:before {
  left: 0;
}
html[dir="rtl"] .search-location-results > ul > li > span:first-of-type:before {
  right: 0;
}
html:not([dir="rtl"]) .search-location-results > ul > li > span:first-of-type {
  margin-right: 7px;
}
html[dir="rtl"] .search-location-results > ul > li > span:first-of-type {
  margin-left: 5px;
}
.search-location-results ul > li > span:first-of-type > span {
  color: red;
}